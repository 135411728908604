import React, { useEffect, useState } from 'react';

const ROOT_PATH = process.env.PUBLIC_URL || '';
const geniusWavePath = `${ROOT_PATH}/index/index.html`;


function StaticPage() {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(geniusWavePath)
            .then(response => response.text())
            .then(html => {
                setContent(html);
            })
            .catch(error => console.error('Error loading static content:', error));
    }, []);

    return <div dangerouslySetInnerHTML={{ __html: content }} />;
}

export default StaticPage;