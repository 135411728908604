import React from 'react';
import PaymentForm from './components/PaymentForm';
import StaticPage from './components/StaticPage';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<StaticPage />} />
                <Route path="/payment" element={<PaymentForm />} />
            </Routes>
        </Router>
    );
}

export default App;